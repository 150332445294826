import types from "./types";
import Creators from "./actions";
import { KpiAPI } from "../../../lib/apiKpi";
import { MorphioAPI, getProfile } from "../../../lib/api";
import { reloadUserUsage } from "../../../_redux/users";
import { getParentMetadata, createTag, editTag, deleteTag } from "_redux/users/async";
import {
    makeSelectRole,
    selectSavedTagsUnformatted,
    selectParentUserId,
    makeSelectUserId,
} from "_redux/users/selectors";
import { selectOverviewTagsAvailable } from "features/ControlPanel/ducks/selectors";
import { logout } from "lib/auth";
import moment from "moment";
import { getAnalyticsConversionTypes } from "_redux/analytics/async";
import { mixpanel } from "components/Mixpanel";

/* [[ ADD A LIB API FOR USING A SITE ]]
 ****************************************** */

export const fetchSites = (userId) => {
    if (!userId) {
        logout();
        return Promise.reject("No userId found");
    }

    return MorphioAPI({
        url: "user/account?user_id=" + userId,
        method: "get",
    });
};

export const favouriteView = ({ pinned, accountId }) => {
    if (!getProfile()) {
        return;
    }
    const userId = getProfile().user_id || getProfile()?.sub;
    return MorphioAPI({
        url: "user/favourite",
        method: "put",
        data: {
            userId,
            accountId,
            pinned,
        },
    });
};

const togglePinnedView = ({ accountId, pinned }) => (dispatch) => {
    return dispatch({
        type: types.TOGGLE_PINNED_VIEW,
        promise: favouriteView({ accountId, pinned }),
        meta: {
            accountId,
            pinned,
        },
    });
};

const updateAccountTags = ({ accountId, tags }) => (dispatch) => {
    return dispatch({
        type: types.UPDATE_TAGS,
        promise: updateSiteSettings({
            account_id: accountId,
            data: {
                tags,
            },
        }),
        meta: {
            accountId,
            tags,
        },
    });
};

export const duplicateSite = (data) => {
    return MorphioAPI({
        url: `account/duplicate`,
        method: "POST",
        data,
    });
};

export const createSite = (data) => {
    return MorphioAPI({
        url: `account`,
        method: "POST",
        data,
    });
};

export const removeSite = (data) => {
    return MorphioAPI({
        url: `account`,
        method: "DELETE",
        data,
    });
};

export const updateSiteSettings = (data) => {
    return MorphioAPI({
        url: "account",
        method: "PUT",
        data: data,
    }).then(({ data }) => {
        return data;
    });
};

export const removeSiteLogo = (accountId) => {
    return MorphioAPI({
        url: `account/${accountId}/logo`,
        method: "DELETE",
    });
};

export const updateSiteLogo = ({ accountId, logo = {} }) => {
    return MorphioAPI({
        url: `account/${accountId}/logo`,
        method: "PUT",
        data: { logo },
    }).then(({ data }) => {
        return data;
    });
};

export const getAccountsData = ({ userId, month }) => {
    const queryParams = {
        userId,
        month: moment(month)
            .startOf("month")
            .format("YYYY-MM-DD"),
        view: "budgets",
    };

    return {
        type: types.ACCOUNT_FETCH_DATA,
        promise: KpiAPI({
            method: "GET",
            url: `/allAccountsPerformance`,
            query: queryParams,
        }).then(({ data }) => {
            return data;
        }),
    };
};

export const getMetricsData = ({ userId, metricDates }) => {
    const queryParams = {
        userId,
        startDate: moment(metricDates.startDate).format("YYYY-MM-DD"),
        endDate: moment(metricDates.endDate).format("YYYY-MM-DD"),
        compareToStart: metricDates.compareToStart ? moment(metricDates.compareToStart).format("YYYY-MM-DD") : null,
        compareToEnd: metricDates.compareToEnd ? moment(metricDates.compareToEnd).format("YYYY-MM-DD") : null,
        view: "metrics",
    };

    return {
        type: types.ACCOUNT_FETCH_METRICS,
        promise: KpiAPI({
            method: "GET",
            url: `/allAccountsPerformance`,
            query: queryParams,
        }).then(({ data }) => {
            return data;
        }),
    };
};

export const getOpportunities = ({ userId }) => {
    return {
        type: types.ACCOUNT_FETCH_OPPORTUNITIES,
        promise: KpiAPI({
            method: "GET",
            url: `/allAccountsPerformance/opportunities`,
            query: {
                userId,
            },
        }).then(({ data }) => {
            return data;
        }),
    };
};

export const getTopWins = ({ userId }) => {
    return {
        type: types.ACCOUNT_FETCH_TOP_WINS,
        promise: KpiAPI({
            method: "GET",
            url: `/allAccountsPerformance/topWins`,
            query: {
                userId,
            },
        }).then(({ data }) => {
            return data;
        }),
    };
};

export const saveBudgetData = ({ value, platform, accountId, month }) => {
    const queryParams = {
        value,
        platform,
        accountId,
        month: moment(month)
            .startOf("month")
            .format("YYYY-MM-DD"),
    };

    return {
        type: types.ACCOUNT_UPDATE_BUDGET,
        meta: {
            ...queryParams,
        },
        promise: KpiAPI({
            method: "PUT",
            url: `/allAccountsPerformance/budget`,
            query: queryParams,
        }).then(({ data }) => {
            return data;
        }),
    };
};

export const saveGoalData = ({ value, metric, accountId, month }) => {
    const queryParams = {
        value,
        metric,
        accountId,
        month: moment(month)
            .startOf("month")
            .format("YYYY-MM-DD"),
    };

    return {
        type: types.ACCOUNT_UPDATE_GOAL,
        meta: {
            ...queryParams,
        },
        promise: KpiAPI({
            method: "PUT",
            url: `/allAccountsPerformance/goal`,
            query: queryParams,
        }).then(({ data }) => {
            return data;
        }),
    };
};

export const toggleAlertSettingData = ({ accountId, type, value }) => {
    const queryParams = {
        value,
        type,
        accountId,
    };

    return {
        type: types.ACCOUNT_UPDATE_ALERT,
        meta: {
            ...queryParams,
        },
        promise: KpiAPI({
            method: "PUT",
            url: `/allAccountsPerformance/alert`,
            query: queryParams,
        }).then(({ data }) => {
            return data;
        }),
    };
};

const duplicateAccount = ({ name, accountId, userId }) => (dispatch, getState) => {
    return dispatch({
        type: types.CREATE_VIEW,
        promise: duplicateSite({ userId, accountId, name }),
        meta: {
            name,
        },
    });
};

const createView = ({ name, userId, tags = [] }) => (dispatch, getState) => {
    const data = {
        user_id: userId,
        data: { name, tags },
    };

    return dispatch({
        type: types.CREATE_VIEW,
        promise: createSite(data),
        meta: {
            name,
        },
    });
};

const removeView = ({ accountId }) => (dispatch, getState) => {
    const userId = getProfile()?.sub;

    const data = {
        user_id: userId,
        account_id: accountId,
    };

    return dispatch({
        type: types.REMOVE_VIEW,
        promise: removeSite(data),
        meta: {
            accountId,
        },
    }).then(async (result) => {
        await dispatch(reloadUserUsage());
        //mixpanel
        mixpanel.track("Account Removed", {
            UserId: userId,
            AccountId: accountId,
        });
        return result;
    });
};

const updateName = ({ accountId, name }) => (dispatch) =>
    dispatch({
        type: types.UPDATE_NAME,
        promise: updateSiteSettings({ account_id: accountId, data: { name } }),
        meta: {
            accountId,
            name,
        },
    });

const removeAccountLogo = (accountId) => (dispatch) =>
    dispatch({
        type: types.REMOVE_ACCOUNT_LOGO,
        promise: removeSiteLogo(accountId),
        meta: {
            accountId,
        },
    });

const updateAccountLogo = ({ accountId, logo }) => (dispatch) =>
    dispatch({
        type: types.UPDATE_ACCOUNT_LOGO,
        promise: updateSiteLogo({ accountId, logo }),
        meta: {
            accountId,
        },
    });

export const getSites = (userId) => {
    return (dispatch, getState) => {
        dispatch(Creators.getSitesPending({ pending: true }));
        dispatch(Creators.getSitesRejected({ error: false, payload: {} }));

        return fetchSites(userId)
            .then((payload) => {
                dispatch(Creators.getSitesPending({ pending: false }));
                dispatch(Creators.getSitesFufilled({ payload }));
                return payload;
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(Creators.getSitesRejected({ error: true, payload: err }));
                dispatch(Creators.getSitesPending({ pending: false }));
                throw new Error("Something went wrong with getting the user's sites");
            });
    };
};

const getChannelKey = (channel) => channel.replace(/\s/g, "").replace(/\W/g, "_");
export const loadAnalyticsChannel = ({ accountId }) => (dispatch) => {
    return dispatch({
        type: types.LOAD_ANALYTICS_CHANNELS,
        promise: KpiAPI({
            method: "GET",
            url: `/analytics/sitewide`,
            query: {
                accountId,
                start_date: moment()
                    .add(-1, "month")
                    .format("YYYY-MM-DD"),
                end_date: moment().format("YYYY-MM-DD"),
                dimensions: "ga:channelGrouping",
                metrics: "ga:sessions",
            },
        }).then(({ data = [] } = {}) => {
            if (!data) return [];
            return data.map(({ channels }) => ({ key: `analytics${getChannelKey(channels)}`, name: channels }));
        }),
        meta: {
            accountId,
        },
    });
};

export const loadAllAnalyticsGoals = ({ accountId }) => (dispatch) => {
    return dispatch({
        type: types.LOAD_ANALYTICS_ALL_GOALS,
        promise: getAnalyticsConversionTypes({ accountId }),
        meta: {
            accountId,
        },
    });
};

const getSuperAdminTags = () => async (dispatch, getState) => {
    const state = getState();
    const role = makeSelectRole()(state);
    const userTags = selectSavedTagsUnformatted(state);
    const parentUserId = selectParentUserId(state);

    // Move tags to control panel redux
    if (role === "super-admin") {
        dispatch(Creators.updateOverviewTagsAvailable(userTags));
        return;
    }

    const { controlPanelSettings: { tags = {} } = {} } = await getParentMetadata({ parentUserId });

    return dispatch(Creators.updateOverviewTagsAvailable(tags));
};

export const createOverviewTag = ({ tagId, tagName }) => async (dispatch, getState) => {
    const state = getState();
    const role = makeSelectRole()(state);
    const userId = makeSelectUserId()(state);
    const parentUserId = selectParentUserId(state);
    const availableTags = selectOverviewTagsAvailable(state);

    let uid = userId;

    if (role !== "super-admin") {
        uid = parentUserId;
    }

    dispatch(Creators.updateOverviewTagsAvailable([...availableTags, { id: tagId, name: tagName }]));
    return createTag({ parentId: uid, tagId, tagName });
};

export const editOverviewTag = ({ tagId, tagName }) => async (dispatch, getState) => {
    const state = getState();
    const role = makeSelectRole()(state);
    const userId = makeSelectUserId()(state);
    const parentUserId = selectParentUserId(state);
    const availableTags = selectOverviewTagsAvailable(state);

    let uid = userId;

    if (role !== "super-admin") {
        uid = parentUserId;
    }

    const updatedTags = availableTags.map(({ id, name }) => {
        if (id === tagId) {
            return { id, name: tagName };
        }

        return { id, name };
    });

    dispatch(Creators.updateOverviewTagsAvailable(updatedTags));
    return editTag({ parentId: uid, tagId, tagName });
};

export const deleteOverviewTag = ({ tagId }) => async (dispatch, getState) => {
    const state = getState();
    const role = makeSelectRole()(state);
    const userId = makeSelectUserId()(state);
    const parentUserId = selectParentUserId(state);
    const availableTags = selectOverviewTagsAvailable(state);

    let uid = userId;

    if (role !== "super-admin") {
        uid = parentUserId;
    }

    const updatedTags = availableTags.filter(({ id }) => id !== tagId);

    dispatch(Creators.updateOverviewTagsAvailable(updatedTags));
    return deleteTag({ parentId: uid, tagId });
};

export default {
    getMetricsData,
    getAccountsData,
    createView,
    removeView,
    togglePinnedView,
    getSites,
    updateName,
    removeAccountLogo,
    updateAccountLogo,
    updateAccountTags,
    getSuperAdminTags,
    duplicateAccount,
};

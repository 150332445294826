import React, { useState } from "react";
import { DialogVFour } from "components/Dialog";
import style from "./style.module.scss";
import Tabs from "components/Tabs";
import EditStep from "./EditStep";
import ReviewStep from "./ReviewStep";
import SavingStep from "features/Dashboard/CampaignGroups/EditCampaign/SavingStep";
import { DEFAULT_ERROR_MESSAGE, EDIT_STEP_INDEX, getErrorMessageFromRes, prepareUpdateCampaignData } from "./helpers";
import { SubmissionError } from "redux-form";

function EditCampaignModal({
    showEditingCampaignModal,
    onCloseModalHandle,
    handleSubmit,
    editingCampaignInfo = {},
    accountId,
    userId,
    integrations,
    initialValues,
    updatePlatformCampaign,
    updatePlatformCampaignProp,
    showUpgrade,
}) {
    const [tabIndex, setTabIndex] = useState(0);
    const { platform = "" } = editingCampaignInfo;

    if (!showEditingCampaignModal) return null;
    const onCloseModal = () => {
        onCloseModalHandle();
        setTabIndex(EDIT_STEP_INDEX);
    };

    const onSubmit = async (formData) => {
        let errorMessage;

        const updatePlatformCampaignFunc = updatePlatformCampaignProp || updatePlatformCampaign;

        try {
            const res = await updatePlatformCampaignFunc(
                prepareUpdateCampaignData(
                    formData,
                    editingCampaignInfo,
                    integrations,
                    initialValues,
                    accountId,
                    userId,
                ),
            );

            errorMessage = getErrorMessageFromRes(res);
        } catch (error) {
            throw new SubmissionError({ _error: DEFAULT_ERROR_MESSAGE });
        }
        if (errorMessage) {
            throw new SubmissionError({ _error: errorMessage });
        }
    };

    return (
        <DialogVFour
            open={showEditingCampaignModal}
            classes={{
                rootClass: style.editCampaignRoot,
                paperClass: style.editCampaignPaper,
            }}
        >
            <Tabs tabType={tabIndex} onChange={setTabIndex} className={style.tabContainer}>
                <EditStep
                    onCloseModalHandle={onCloseModal}
                    showUpgrade={showUpgrade}
                    editingCampaignInfo={editingCampaignInfo}
                />
                <ReviewStep
                    onUpdateCampaign={handleSubmit(onSubmit)}
                    onCloseModalHandle={onCloseModal}
                    editingCampaignInfo={editingCampaignInfo}
                />
                <SavingStep platform={platform} onCloseModalHandle={onCloseModal} />
            </Tabs>
        </DialogVFour>
    );
}
export default EditCampaignModal;

import React from "react";
import StepContainer from "../StepContainer";
import CampaignTitle from "../CampaignTitle";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.scss";
import ChangeCompare from "../ChangeCompare";
import Button from "components/Button";
import Status from "../../Status";
import { clearSubmitErrors, getFormError, getFormInitialValues, getFormValues } from "redux-form";
import getSymbolFromCurrency from "currency-symbol-map";
import {
    DANGEROUS_CHANGE_PERCENT,
    EDIT_CAMPAIGN_NAME,
    EDIT_STEP_INDEX,
    getBudgetChangePercent,
    getBudgetWarn,
    SAVING_STEP_INDEX,
} from "../helpers";
import ChangeWarn from "../ChangeWarn";
import { getPlatformStatus } from "lib/campaignUtils";
import { channelFriendlyName } from "lib/utility";

const StatusValue = ({ status, platform }) => {
    return <Status status={status} platform={platform} normalTextColor className={style.status} />;
};

const BudgetValue = ({ budget, symbol }) => {
    return (
        <p className={style.budget}>
            {symbol}
            {budget}
        </p>
    );
};

function ReviewStep({ changeType: changeTabIndex, onUpdateCampaign, onCloseModalHandle, editingCampaignInfo }) {
    const dispatch = useDispatch();
    const { campaign, campaignType, platform, currency, budgetType = "" } = editingCampaignInfo;
    const symbol = getSymbolFromCurrency(currency);
    const budgetField = `${budgetType}Budget`;
    const {
        [budgetField]: currentBudget,
        status: { value: currentStatus },
    } = useSelector(getFormValues(EDIT_CAMPAIGN_NAME));
    const {
        [budgetField]: previousBudget,
        status: { value: previousStatus },
    } = useSelector(getFormInitialValues(EDIT_CAMPAIGN_NAME));
    const formError = useSelector(getFormError(EDIT_CAMPAIGN_NAME));
    const isBudgetChanged = currentBudget !== previousBudget;
    const isStatusChanged = currentStatus !== previousStatus;
    const changePercent = Number(isBudgetChanged) && getBudgetChangePercent(currentBudget, previousBudget);
    const showBudgetWarn = Math.abs(changePercent) >= DANGEROUS_CHANGE_PERCENT;

    const onUpdateHandle = () => {
        changeTabIndex(SAVING_STEP_INDEX);
        onUpdateCampaign();
    };

    const onBackHandle = () => {
        changeTabIndex(EDIT_STEP_INDEX);
        dispatch(clearSubmitErrors(EDIT_CAMPAIGN_NAME));
    };

    return (
        <StepContainer
            title="Review Campaign"
            subtitle="Review the following changes to the campaign."
            onCloseHandle={onCloseModalHandle}
        >
            <CampaignTitle platform={platform} type={campaignType} name={campaign} />
            <div className={style.reviewChanges}>
                <p>Review Changes</p>
                {isBudgetChanged && (
                    <ChangeCompare
                        title="Campaign Budget"
                        currentValue={<BudgetValue budget={currentBudget} symbol={symbol} />}
                        previousValue={<BudgetValue budget={previousBudget} symbol={symbol} />}
                        warn={showBudgetWarn && <ChangeWarn title="Budget Alert" warn={getBudgetWarn(changePercent)} />}
                    />
                )}
                {isStatusChanged && (
                    <ChangeCompare
                        title="Campaign Status"
                        currentValue={
                            <StatusValue status={getPlatformStatus(platform)[currentStatus]} platform={platform} />
                        }
                        previousValue={
                            <StatusValue status={getPlatformStatus(platform)[previousStatus]} platform={platform} />
                        }
                    />
                )}
            </div>
            {formError && <ChangeWarn warn={formError} />}
            <p className={style.warm}>
                <span>Disclaimer:</span>
                &nbsp;You are about to push changes to {channelFriendlyName(platform)} and will adjust your campaign
                settings.
            </p>
            <div className={style.buttonGroup}>
                <Button secondary onClick={onBackHandle}>
                    Back
                </Button>
                <Button onClick={onUpdateHandle}>Apply</Button>
            </div>
        </StepContainer>
    );
}

export default ReviewStep;

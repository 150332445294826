import React from "react";
import StepContainer from "../StepContainer";
import { useSelector } from "react-redux";
import CampaignTitle from "../CampaignTitle";
import style from "./style.module.scss";
import { Field, isPristine } from "redux-form";
import MultiSelector from "components/MultiSelector";
import Status from "features/Dashboard/CampaignGroups/Status";
import Button from "components/Button";
import { EDIT_CAMPAIGN_NAME, getCampaignUpdateInfo, REVIEW_STEP_INDEX } from "../helpers";
import { history } from "_redux";
import { ReactComponent as UpgradeIcon } from "assets/images/Menu/Icon-Upgrade.svg";
import DisableToolTip from "./DisableTooltip";
import BudgetField from "./BudgetField";

function EditStep({ onCloseModalHandle, changeType, showUpgrade, editingCampaignInfo }) {
    const { campaign, campaignType, platform, currency, budgetType = "", readOnly, status } = editingCampaignInfo;
    const isNotChanged = useSelector(isPristine(EDIT_CAMPAIGN_NAME));

    const { isStatusDisabled, isBudgetDisabled, statusOptions, disableCause, symbol } = getCampaignUpdateInfo({
        platform,
        readOnly,
        budgetType,
        currency,
        status,
    });

    if (showUpgrade) {
        return (
            <div className={style.upgradeContainer}>
                <h2>Upgrade Now</h2>
                <p>
                    You do not have access to this feature under your plan. Please upgrade to one of the{" "}
                    <span>Pro Plans</span> in order to activate this feature.
                </p>
                <div className={style.buttons}>
                    <Button
                        vibrantBlue
                        icon={UpgradeIcon}
                        onClick={() => {
                            history.push("/dashboard/user/plans");
                        }}
                    >
                        Upgrade Now
                    </Button>
                    <Button secondary onClick={onCloseModalHandle}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <StepContainer
            title="Edit Campaign"
            subtitle="Edit the budget and/or status for this campaign. Note, you can only make changes at the campaign level and not at the ad group/ad set level."
            onCloseHandle={onCloseModalHandle}
        >
            <CampaignTitle platform={platform} type={campaignType} name={campaign} />

            <BudgetField
                isDisabled={isBudgetDisabled}
                disableCause={disableCause}
                symbol={symbol}
                campaign={campaign}
                budgetType="daily"
            />

            <BudgetField
                isDisabled={isBudgetDisabled}
                disableCause={disableCause}
                symbol={symbol}
                campaign={campaign}
                budgetType="lifetime"
            />

            <BudgetField
                isDisabled={isBudgetDisabled}
                disableCause={disableCause}
                symbol={symbol}
                campaign={campaign}
                budgetType="unlimited"
            />

            <div className={style.statusSelect}>
                <p className={style.labelInput}>
                    <span>Campaign Status</span>
                    {(isStatusDisabled || !disableCause) && (
                        <DisableToolTip id={`status-${campaign}`} dataTip={disableCause} />
                    )}
                </p>
                <Field
                    component={MultiSelector}
                    name="status"
                    className={style.multiSelect}
                    options={statusOptions}
                    clearable={false}
                    darkBorder
                    disabled={isStatusDisabled}
                    optionRenderer={(option) => (
                        <Status
                            status={option.label}
                            platform={platform}
                            className={style.status}
                            normalTextColor={true}
                        />
                    )}
                    valueRenderer={(option) => (
                        <Status
                            status={option.label}
                            platform={platform}
                            className={style.selectedStatus}
                            normalTextColor={true}
                        />
                    )}
                />
            </div>
            <div className={style.buttonGroup}>
                <Button secondary onClick={onCloseModalHandle}>
                    Cancel
                </Button>
                <Button disabled={isNotChanged} onClick={() => changeType(REVIEW_STEP_INDEX)}>
                    Next
                </Button>
            </div>
        </StepContainer>
    );
}

export default EditStep;
